import jwt from "jsonwebtoken";
import { Navigate } from "react-router-dom";
import { getAccessToken } from "../helpers/auth.helper";
import { PermissionDenied } from "../views/PermissionDenied";

interface IAuthProps {
  roles?: string[];
  children: any;
}

export const Auth = (props: IAuthProps) => {
  let isExpired = true;
  let role = null;
  const accessToken = getAccessToken();
  if (accessToken) {
    const decodedToken: any = jwt.decode(accessToken, { complete: true });
    let dateNow = new Date();
    if (decodedToken.payload.exp < dateNow.getTime()) {
      isExpired = false;
    }
    role = decodedToken.payload.role;
  }

  if (!accessToken || isExpired) {
    return <Navigate to="/login" replace={true} />;
  } else if (
    accessToken &&
    !isExpired &&
    ((props.roles && props.roles.includes(role)) || !props.roles)
  ) {
    return props.children;
  } else {
    return <PermissionDenied />;
  }
};
