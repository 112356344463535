import types from "../actions/actionTypes";

export interface IUserSettings {
  // [key: string]: any;
  layers: boolean;
}

const initialState: IUserSettings = {
  layers: true,
};

export const userSettings = (
  state: IUserSettings = initialState,
  action: any
): IUserSettings => {
  switch (action.type) {
    case types.SET_USER_SETTINGS:
      return { ...state, [action.payload.setting]: action.payload.value };
    default:
      return state;
  }
};
