import React, { FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Form, Button, Alert } from "react-bootstrap";

import "./Login.css";
import { IState } from "../reducers";
import { sendRequest } from "../helpers/utils";
import { Link, Navigate } from "react-router-dom";

export function Login() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [permanent, setPermanent] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(
    (state: IState) => state.user.authenticated
  );
  const handleLogin = async (
    e: FormEvent<HTMLFormElement>,
    email: string,
    password: string,
    permanent: boolean
  ) => {
    const body = { email, password, permanent };
    e.preventDefault();

    setError(false);
    await sendRequest(
      `//${process.env.REACT_APP_APIHOST}/login`,
      { method: "POST", body: body },
      (data) => dispatch({ type: "LOGIN_SUCCESS", value: data }),
      (error) => {
        setError(true);
        dispatch({ type: "LOGIN_FAILURE", value: error.message });
      }
    );
  };

  return !isAuthenticated ? (
    <div className="centerWrap">
      <Container className="loginWrapper">
        {error ? <Alert variant="danger">Wrong login or password</Alert> : ""}
        <Form onSubmit={(e) => handleLogin(e, email, password, permanent)}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter email"
              className="text-muted"
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(evt.target.value)
              }
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(evt.target.value)
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Permanent login"
              checked={permanent}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPermanent(!permanent)
              }
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Sign In
          </Button>
        </Form>
        register
        <Link to="/register">Register</Link>
      </Container>
    </div>
  ) : (
    <Navigate to="/" replace></Navigate>
  );
}
