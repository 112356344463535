import types from "../actions/actionTypes";
// import { ITrackerSetting } from "../components/Map";

export interface ITracker {
  show: boolean;
  deviceName: string;
  deviceInitials: string;
  color: string;
  temperature: number;
  humidity: number;
  voltage: number;
  speed: number;
  satellites: number;
  dev_eui: string;
}
export interface ITrackers {
  [key: string]: ITracker;
}
const defaultState = {};

export const trackers = (
  state: ITrackers = defaultState,
  action: any
): ITrackers => {
  switch (action.type) {
    case types.ADD_TRACKERS:
      console.log("Adding trackers", action.payload);
      let ret: ITrackers = {};
      Object.keys(action.payload).forEach((key: any) => {
        ret[key] = action.payload[key];
      });
      return { ...state, ...ret };
    case types.UPDATE_TRACKER:
      console.log("Updating trackers");
      return {
        ...state,
        [action.payload.dev_eui]: {
          ...state[action.payload.dev_eui],
          show: !state[action.payload.dev_eui].show,
        },
      };
    case types.SHOW_ONLY:
      const trackers: any = {};
      Object.keys(state).forEach((dev_eui: string) => {
        if (dev_eui !== action.payload) {
          trackers[dev_eui] = { ...state[dev_eui], show: false };
        } else {
          trackers[dev_eui] = { ...state[dev_eui], show: true };
        }
      });
      return trackers;
    default:
      return state;
  }
};
