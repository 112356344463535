import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { IState } from "../../reducers";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import types from "../../actions/actionTypes";
import { IPositions } from "../../reducers/positions";
import { sendRequest } from "../../helpers/utils";
import { parsePositions, parseTrackerSettings } from "../../helpers/loraUtils";
import { ITrackers } from "../../reducers/trackers";

export const SettingsModal: React.FC = () => {
  const dispatch = useDispatch();
  const show = useSelector((state: IState) => state.modals.settingsModal);
  const trackers = useSelector((state: IState) => state.trackers);
  const userSettings: any = useSelector((state: IState) => state.userSettings);
  const [age, setAge] = useState<number>(120);

  const handleClose = () => {
    dispatch({
      type: "SET_MODAL",
      payload: { modalId: "settingsModal", value: false },
    });
  };

  const handleChangeAge = async (event: SelectChangeEvent) => {
    const historyAge = Number(event.target.value);
    setAge(historyAge);
    await sendRequest(
      `//${process.env.REACT_APP_APIHOST}/history/${historyAge * 60 * 1000}`,
      { method: "GET", useToken: true },
      (data) => {
        console.log("history data", data);
        const historyPositions: IPositions = parsePositions(data) as IPositions;
        const trackerSettings: ITrackers = parseTrackerSettings(data);

        // const trackerSettings: ITrackers = parseTrackerSettings(data);
        console.log("setting history positions");

        dispatch({ type: "SET_POSITIONS", payload: historyPositions });
        dispatch({ type: "ADD_TRACKERS", payload: trackerSettings });
      },
      () => {}
    );
  };

  const handleChange = (
    evt: ChangeEvent<HTMLInputElement>,
    dev_eui: string
  ) => {
    console.log(evt.target.value);
    dispatch({ type: types.UPDATE_TRACKER, payload: { dev_eui } });
  };
  const handleSetSettings = (
    evt: ChangeEvent<HTMLInputElement>,
    setting: string
  ) => {
    console.log(evt.target.value);
    dispatch({
      type: types.SET_USER_SETTINGS,
      payload: { setting, value: !userSettings[setting] },
    });
  };
  const style = {
    position: "absolute" as "absolute",
    top: "10%",
    left: "50%",
    transform: "translate(-50%, -10%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflow: "scroll" }}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h4" component="h1">
          Nastavenia
        </Typography>
        <Typography
          component="span"
          id="modal-modal-description"
          sx={{ mt: 2 }}
        >
          <FormControl variant="standard">
            <Typography id="modal-modal-subtitle" variant="h6" component="h2">
              Zobrazit trackery na mape
            </Typography>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {Object.keys(trackers).map((dev_eui) => {
                let tracker = trackers[dev_eui];
                return (
                  <div
                    key={dev_eui}
                    style={{ flexGrow: 1, width: "15%", minWidth: "150px" }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={tracker.show}
                            onChange={(evt) => handleChange(evt, dev_eui)}
                            name={dev_eui}
                          />
                        }
                        label={tracker.deviceName}
                      />
                    </FormGroup>
                  </div>
                );
              })}
            </div>
            <Typography id="modal-modal-subtitle" variant="h6" component="h2">
              Zobrazit vrstevnice
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={userSettings.layers}
                    onChange={(evt) => handleSetSettings(evt, "layers")}
                    name="layers"
                  />
                }
                label="Vrstevnice"
              />
            </FormGroup>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="last-positions-age-label">
              historia trasy
            </InputLabel>
            <Select
              labelId="last-positions-age-label"
              id="last-positions-age"
              value={age.toString()}
              label="historia trasy"
              onChange={handleChangeAge}
            >
              <MenuItem value={5}>5 minut</MenuItem>
              <MenuItem value={120}>2 hodiny</MenuItem>
              <MenuItem value={350}>5 hodin</MenuItem>
              <MenuItem value={1440}>1 den</MenuItem>
              <MenuItem value={2880}>2 dni</MenuItem>
              <MenuItem value={10080}>tyzden</MenuItem>
            </Select>
          </FormControl>
        </Typography>
      </Box>
    </Modal>
  );
};
