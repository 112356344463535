import types from "../actions/actionTypes";

interface IUserSettings {
  mapCenter: {
    lat: number;
    lng: number;
  };
  mapZoom: number;
}
export interface IUserDetails {
  email: string;
  password: string;
  role: string;
  organization: string;
  devices: string[];
  settings?: IUserSettings;
}

const initialState: IUserDetails = {
  email: "",
  password: "",
  role: "",
  organization: "",
  devices: [],
  settings: { mapCenter: { lat: 48, lng: 21 }, mapZoom: 12 },
};

export const userDetails = (
  state: IUserDetails = initialState,
  action: any
): IUserDetails => {
  switch (action.type) {
    case types.SET_USER:
      return action.payload;
    default:
      return state;
  }
};
