export const getAccessToken = () => {
  const user: string | null = localStorage.getItem("user");
  if (user !== null) {
    const { accessToken } = JSON.parse(user);
    return accessToken;
  }
  return undefined;
};

export const getRefreshToken = () => {
  const user: string | null = localStorage.getItem("user");
  if (user !== null) {
    const { refreshToken } = JSON.parse(user);
    return refreshToken;
  }
  return undefined;
};
