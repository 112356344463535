import React, { FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { Container, Form, Button, Alert } from "react-bootstrap";

import "./Login.css";
import { sendRequest } from "../helpers/utils";

export function Register() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [password1, setPassword1] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [agree, setAgree] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleRegister = async (
    e: FormEvent<HTMLFormElement>,
    email: string,
    password: string
  ) => {
    if (password !== password1) {
      return;
    }
    const body = { email, password };
    e.preventDefault();

    setError(false);
    await sendRequest(
      `//${process.env.REACT_APP_APIHOST}/register`,
      { method: "POST", body: body },
      (data) => dispatch({ type: "LOGIN_SUCCESS", value: data }),
      (error) => {
        setError(true);
        dispatch({ type: "LOGIN_FAILURE", value: error.message });
      }
    );
  };
  return (
    <div className="centerWrap">
      <Container className="loginWrapper">
        <h1>Registrácia</h1>
        {error ? <Alert variant="danger">Chyba</Alert> : ""}
        <Form onSubmit={(e) => handleRegister(e, email, password)}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Email"
              className="text-muted"
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(evt.target.value)
              }
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Heslo</Form.Label>
            <Form.Control
              type="password"
              placeholder="Heslo"
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(evt.target.value)
              }
            />
          </Form.Group>
          <Form.Group controlId="formBasicPassword1">
            <Form.Label>Heslo zopakuj</Form.Label>
            <Form.Control
              type="password"
              placeholder="Heslo zopakuj"
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                setPassword1(evt.target.value)
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Prevádzkovateľ nezodpovedá za žiadne škody. Všetko je na vlastné riziko"
              checked={agree}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAgree(!agree)
              }
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Registruj
          </Button>
        </Form>
      </Container>
    </div>
  );
}
