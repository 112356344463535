import { applyMiddleware, createStore, PreloadedState } from "redux";
import { createBrowserHistory } from "history";
import { mainReducer } from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware } from "connected-react-router";

export const history = createBrowserHistory();

export const configureStore = (preloadedState?: PreloadedState<any>) => {
  return createStore(
    mainReducer(history),
    preloadedState,
    composeWithDevTools(applyMiddleware(routerMiddleware(history)))
  );
};
