import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import ShareIcon from "@mui/icons-material/Share";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface IProps {
  title: string;
  initials: string;
  eui: string;
  color: string;
  deviceId: string;
  selected: boolean;
  handleSelectClick: (e: React.MouseEvent, deviceId: string) => void;
}

export const TrackerCard = (props: IProps) => {
  return (
    <Card
      sx={{ maxWidth: 345, margin: "10px", cursor: "pointer" }}
      onClick={(e) => props.handleSelectClick(e, props.deviceId)}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: props.color }} aria-label={props.title}>
            {props.initials}
          </Avatar>
        }
        title={props.title}
        subheader={props.eui}
      />
      <CardContent></CardContent>
      <CardActions disableSpacing>
        <IconButton
          aria-label="share"
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
        >
          <ShareIcon />
        </IconButton>
        <IconButton
          aria-label="check"
          style={{ marginLeft: "auto" }}
          onClick={(e) => props.handleSelectClick(e, props.deviceId)}
        >
          {props.selected ? (
            <CheckCircleIcon color="primary" />
          ) : (
            <CheckCircleOutlineIcon color="primary" />
          )}
        </IconButton>
      </CardActions>
    </Card>
  );
};
