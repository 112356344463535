import Map from "./components/Map";
import "./App.css";

import { useDispatch } from "react-redux";
import { Login } from "./components/Login";
import { Logout } from "./components/Logout";
import { HashRouter, Routes, Route } from "react-router-dom";
import { Layout } from "./components/layouts/layout";
import { NotFound } from "./views/notFound";
// import { MapLayout } from "./components/layouts/mapLayout";
import { SettingsModal } from "./components/modals/SettingsModal";
import { useEffect } from "react";
import types from "./actions/actionTypes";
import { Register } from "./components/Register";
import { Trackers } from "./views/Trackers";
import { Users } from "./views/Users";

function App() {
  const dispatch = useDispatch();
  const updateNetwork = () => {
    console.log("network", window.navigator.onLine);
    dispatch({ type: types.ONLINE, payload: window.navigator.onLine });
  };
  useEffect(() => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
  });
  return (
    <>
      <SettingsModal />
      <HashRouter basename={window.location.pathname}>
        <Routes>
          {/* <div className="fullHeight"> */}
          <Route
            path="/"
            element={<Layout title="Map" Component={<Map />} />}
          />
          <Route
            path="/map"
            element={<Layout title="Map" Component={<Map />} />}
          />
          <Route
            path="/devices"
            element={<Layout title="Trackers" Component={<Trackers />} />}
          />
          <Route
            path="/users"
            element={<Layout title="Users" Component={<Users />} />}
          />
          <Route
            path="/share/:id"
            element={<Layout title="Map" Component={<Map />} />}
          />
          {/* <Route path="/admin"  element={AdminContainer} /> */}
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          {/* </div> */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
