import jwt from "jsonwebtoken";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
} from "@mui/material";
import { Link } from "react-router-dom";
import { getAccessToken } from "../../helpers/auth.helper";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface IProps {
  roles?: string[];
  title: string;
  link: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string };
}

export const MenuItem = (props: IProps) => {
  let isExpired = true;
  let role = null;
  const accessToken = getAccessToken();
  if (accessToken) {
    const decodedToken: any = jwt.decode(accessToken, { complete: true });
    let dateNow = new Date();
    if (decodedToken.payload.exp < dateNow.getTime()) {
      isExpired = false;
    }
    role = decodedToken.payload.role;
  }

  if (
    accessToken &&
    !isExpired &&
    ((props.roles && props.roles.includes(role)) || !props.roles)
  ) {
    return (
      <Link to={props.link}>
        <ListItemButton>
          <ListItemIcon>{<props.icon />}</ListItemIcon>
          <ListItemText primary={props.title} />
        </ListItemButton>
      </Link>
    );
  } else {
    return <></>;
  }
};
