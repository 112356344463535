import Button from "react-bootstrap/Button";
import { useEffect, useRef, useState } from "react";
import { LatLngExpression, Map as LeafletMap } from "leaflet";
import vrstevnica_108 from "../data/vrstevnica_108.json";
import vrstevnica_110 from "../data/vrstevnica_110.json";
import vrstevnica_111_25 from "../data/vrstevnica_111_25.json";
import vrstevnica_113 from "../data/vrstevnica_113.json";
import SettingsIcon from "@mui/icons-material/Settings";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import { getAccessToken } from "../helpers/auth.helper";
// import { HeatmapLayer } from "react-leaflet-heatmap-layer-v3";

import {
  MapContainer,
  TileLayer,
  GeoJSON,
  FeatureGroup,
  Circle,
  Polygon,
  Popup,
} from "react-leaflet";

import "./Map.css";
import { Auth } from "./Auth";
import Control from "react-leaflet-custom-control";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../reducers";
import { ITrackers } from "../reducers/trackers";
import types from "../actions/actionTypes";
import { Bubble } from "./Bubble";
import { DeviceMarker } from "./map/DeviceMarker";
import { EditControl } from "react-leaflet-draw";
import {
  isArrayEqual,
  latlngsToArray,
  latlngToArray,
  sendRequest,
} from "../helpers/utils";
import {
  onDeleted,
  onEdited,
  onEndDrawing,
  // onMounted,
  // onEndDrawing,
} from "../helpers/drawUtils";
import { parsePositions, parseTrackerSettings } from "../helpers/loraUtils";
import { IPositions } from "../reducers/positions";
import { ButtonGroup } from "react-bootstrap";

interface IDevicePosition {
  [key: string]: any;
}
interface IWsMessage {
  messageType: "HISTORY_POSITIONS" | "DEVICE_POSITION";
  data: [] | IDevicePosition;
}

interface IProps {}

export const Map: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const trackers = useSelector((state: IState) => state.trackers);
  const positions = useSelector((state: IState) => state.positions);
  const isConnected = useSelector((state: IState) => state.user.isConnected);
  const isOnline = useSelector((state: IState) => state.user.isOnline);
  const userSettings = useSelector((state: IState) => state.userSettings);
  const wsRef: any = useRef(null);
  const [areas, setAreas] = useState<any>([]);
  // const [heatmap, setHeatmap] = useState<any>([]);
  const [mapCenter] = useState<LatLngExpression>([
    48.787094, 22.014426,
    // 48, 22,
  ]);
  const [waitingToReconnect, setWaitingToReconnect] = useState<Boolean | null>(
    null
  );
  const [fgRef, setFgRef] = useState<any>(null);
  const [isDrawMounted, setIsDrawMounted] = useState<boolean>(false);
  const [map, setMap] = useState<null | LeafletMap>(null);

  // const onMessage = (m: any) => {};

  useEffect(() => {
    if (waitingToReconnect) {
      return;
    }

    if (!wsRef.current) {
      const ws = new WebSocket(
        `${process.env.REACT_APP_USESSL === "true" ? "wss" : "ws"}://${
          process.env.REACT_APP_WSHOST
        }/ws?token=${getAccessToken()}`
      );
      wsRef.current = ws;
      ws.onopen = () => {
        console.log("ws opened");
        dispatch({ type: types.WS_CONNECTED, payload: true });
      };
      ws.onerror = (e: any) => {
        console.log("ws error", e);
        dispatch({ type: types.WS_CONNECTED, payload: false });
      };
      ws.onclose = (e: any) => {
        dispatch({ type: types.WS_CONNECTED, payload: false });

        console.log("closed", e);
        if (wsRef.current) {
          console.log("ws closed by server");
        } else {
          // Cleanup initiated from app side, can return here, to not attempt a reconnect
          console.log("ws closed by app component unmount");
          return;
        }
        if (waitingToReconnect) {
          console.log("waiting to reconnect...");

          return;
        }
        setWaitingToReconnect(true);
        setTimeout(() => setWaitingToReconnect(null), 5000);
      };

      return () => {
        wsRef.current = null;
        ws.close();
      };
    }
  }, [waitingToReconnect, dispatch]);

  useEffect(() => {
    const onMessage = (m: MessageEvent) => {
      const message: IWsMessage = JSON.parse(m.data);
      console.log("GOT MESSAGE TYPE", message.messageType);
      console.log("message: ", message);
      let data: [] | IDevicePosition = [];
      switch (message.messageType) {
        case "HISTORY_POSITIONS":
          data = message.data;
          const parsedPositions = parsePositions(data);
          const historyPositions = parsedPositions as IPositions;
          const trackerSettings: ITrackers = parseTrackerSettings(data);
          console.log("setting history positions");

          dispatch({ type: "ADD_TRACKERS", payload: trackerSettings });
          dispatch({ type: "SET_POSITIONS", payload: historyPositions });
          break;
        case "DEVICE_POSITION":
          data = message.data as IDevicePosition;
          dispatch({
            type: "ADD_POSITION",
            payload: data,
          });
          if (!Object.keys(trackers).includes(data.dev_eui)) {
            console.log(`tracker ${data.dev_eui} not in list of trackers`);
            let tracker: ITrackers = {};
            tracker[data.dev_eui] = {
              show: true,
              deviceName: data.deviceName,
              deviceInitials: data.initials ? data.initials : "unknown",
              color: data.color ? data.color : "#ff0000",
              temperature: data.temperature,
              humidity: data.humidity,
              speed: data.speed,
              satellites: data.satellites,
              voltage: data.voltage,
              dev_eui: data.dev_eui,
            };
            dispatch({ type: "ADD_TRACKERS", payload: tracker });
          }
          break;
        default:
          break;
      }
    };

    if (waitingToReconnect) {
      return;
    }
    if (!wsRef.current) return;
    console.log("setting ws funcs");
    wsRef.current.onmessage = onMessage;
  }, [waitingToReconnect, trackers, dispatch]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      await sendRequest(
        `//${process.env.REACT_APP_APIHOST}/user`,
        { method: "GET", useToken: true },
        (data) => {
          dispatch({ type: types.SET_USER, payload: data });
          if (map && data.settings) {
            map?.setView(
              [data.settings.mapCenter.lat, data.settings.mapCenter.lng],
              data.settings.mapZoom
            );
          }
        },
        () => {}
      );
    };
    // const fetchHeatmap = async () => {
    //   await sendRequest(
    //     `//${process.env.REACT_APP_APIHOST}/heatmap`,
    //     { method: "GET", useToken: true },
    //     (data) => {
    //       setHeatmap(data);
    //     },
    //     () => {}
    //   );
    // };
    const fetchData = async () => {
      await sendRequest(
        `//${process.env.REACT_APP_APIHOST}/area`,
        { method: "GET", useToken: true },
        (data) => {
          const a: any[] = [];
          for (const item of data.result) {
            a.push(item);
          }
          setAreas(a);
          setAreas((currentAreas: any) => {
            return a.map((currentArea: any) => {
              let leafletId = null;
              fgRef.eachLayer((layer: any) => {
                let arrayToCompare = null;
                layer._latlng
                  ? (arrayToCompare = latlngToArray(layer._latlng))
                  : (arrayToCompare = latlngsToArray(layer._latlngs));
                if (
                  isArrayEqual(currentArea.location.coordinates, arrayToCompare)
                ) {
                  leafletId = layer._leaflet_id;
                }
              });
              return { ...currentArea, leafletId, doNotRender: false };
            });
          });
        },
        () => {}
      );
    };
    if (isDrawMounted) {
      fetchData();
      fetchUserDetails();
      // fetchHeatmap();
    }
  }, [isDrawMounted, fgRef, map, dispatch]); // Or [] if effect doesn't need props or state

  const endDrawing = async (e: any) => {
    const res: any = await onEndDrawing(e);
    res.leafletId = e.layer._leaflet_id;
    res.doNotRender = true;
    setAreas((oldAreas: any) => [...oldAreas, res]);
  };

  const deleted = async (e: any) => {
    const deletedLayersIds = Object.keys(e.layers._layers);
    await onDeleted(e);
    setAreas((prevAreas: any) => {
      return prevAreas.filter(
        (area: any) => !deletedLayersIds.includes(area.leafletId)
      );
    });
  };

  const onMounted = (e: any) => {
    setIsDrawMounted(true);
  };

  const whenCreated = (map: LeafletMap) => {
    setMap(map);
    map.on("moveend", (e) => {
      // console.log(map.getCenter(), map.getZoom());
    });
  };

  const setCenter = async () => {
    const body = {
      lat: map?.getCenter().lat,
      lng: map?.getCenter().lng,
      zoom: map?.getZoom(),
    };
    await sendRequest(
      `//${process.env.REACT_APP_APIHOST}/user/settings`,
      { method: "PATCH", useToken: true, body },
      (data) => {
        console.log("success", data);
      },
      (e) => {
        console.log("error", e);
      }
    );
  };

  return (
    <Auth roles={["USER", "ADMIN", "SUPERADMIN"]}>
      <MapContainer
        tap={false}
        center={mapCenter}
        zoom={14}
        scrollWheelZoom={true}
        whenCreated={(map: LeafletMap) => whenCreated(map)}
        dragging={true}
      >
        {/* <HeatmapLayer
          points={heatmap}
          longitudeExtractor={(m: any) => m["_id"].lng}
          latitudeExtractor={(m: any) => m["_id"].lat}
          intensityExtractor={(m: any) => m.count}
          radius={20}
        /> */}
        <FeatureGroup
          ref={(el) => {
            setFgRef(el);
          }}
        >
          <EditControl
            // ref={editRef}
            draw={{
              polygon: true,
              circle: true,
              circlemarker: false,
              marker: false,
              polyline: false,
              rectangle: false,
            }}
            position="topleft"
            onCreated={endDrawing}
            onDeleted={deleted}
            onEdited={(e: any) => {
              // edited(e);
              onEdited(e, setAreas);
            }}
            onMounted={onMounted}
          />
          {areas
            .filter((a: any) => !a.doNotRender)
            .map((item: any) => {
              if (item.location.type === "Point") {
                return (
                  <Circle
                    key={item._id}
                    center={item.location.coordinates}
                    radius={item.radius}
                  >
                    <Popup>{item._id}</Popup>
                  </Circle>
                );
              } else {
                return (
                  <Polygon
                    key={item._id}
                    positions={item.location.coordinates}
                  ></Polygon>
                );
              }
            })}
        </FeatureGroup>
        <Control key="bl" prepend position="bottomleft">
          <ButtonGroup size="sm">
            <Button
              key="settings"
              color="inherit"
              size="sm"
              onClick={() => {
                dispatch({
                  type: "SET_MODAL",
                  payload: { modalId: "settingsModal", value: true },
                });
              }}
            >
              <SettingsIcon />
            </Button>
            <Button
              key="mapCenter"
              color="inherit"
              size="sm"
              onClick={() => setCenter()}
            >
              <LocationSearchingIcon />
            </Button>
          </ButtonGroup>
        </Control>
        <Control key="tr" position="topright">
          <Bubble isOnline={isConnected && isOnline} />
        </Control>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxZoom={30}
          detectRetina={true}
        />

        {Object.keys(positions)
          .filter((key: any) => trackers[key]?.show)
          .map((dev_eui) => {
            const boat = positions[dev_eui];

            return (
              <DeviceMarker
                key={dev_eui}
                device={boat[boat.length - 1]}
                devicePositions={boat}
              ></DeviceMarker>
            );
          })}
        <>
          <GeoJSON
            key="vrstevnica_113"
            attribution="Plavba.sk"
            data={vrstevnica_113.features as any}
            style={{
              weight: userSettings.layers ? 1 : 0,
              color: "#D8FFFF",
              fill: true,
              fillOpacity: userSettings.layers ? 1 : 0,
            }}
          />
          <GeoJSON
            key="vrstevnica_111"
            data={vrstevnica_111_25.features as any}
            style={{
              weight: userSettings.layers ? 1 : 0,
              color: "#BFF7F7",
              fill: true,
              fillOpacity: userSettings.layers ? 1 : 0,
            }}
          />
          <GeoJSON
            key="vrstevnica_110"
            data={vrstevnica_110.features as any}
            style={{
              weight: userSettings.layers ? 1 : 0,
              color: "#a2f1f1",
              fill: true,
              fillOpacity: userSettings.layers ? 1 : 0,
            }}
          />
          <GeoJSON
            key="vrstevnica_108"
            data={vrstevnica_108.features as any}
            style={{
              weight: userSettings.layers ? 1 : 0,
              color: "#8fd6d6",
              fill: true,
              fillOpacity: userSettings.layers ? 1 : 0,
            }}
          />
        </>
      </MapContainer>
    </Auth>
  );
};

export default Map;
