import { Popup } from "react-leaflet";
import SpeedIcon from "@mui/icons-material/Speed";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import OpacityIcon from "@mui/icons-material/Opacity";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import Battery4BarIcon from "@mui/icons-material/Battery4Bar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./DevicePopup.css";

interface IDevicePopup {
  deviceName: string;
  speed: number;
  temperature: number;
  humidity: number;
  voltage: number;
  satellites: number;
  hideOthers: () => void;
}
export const DevicePopup: React.FC<IDevicePopup> = (props) => {
  return (
    <Popup>
      <div className="wrapper">
        <div className="row">
          <span className="icon">
            <VisibilityIcon
              fontSize="small"
              onClick={() => props.hideOthers()}
              style={{ cursor: "pointer", marginRight: "5px" }}
            />
          </span>
          <strong>{props.deviceName}</strong>
        </div>
        <div className="row">
          <span className="icon">
            <DeviceThermostatIcon fontSize="small" />
          </span>
          {props.temperature} ℃
        </div>
        <div className="row">
          <span className="icon">
            <OpacityIcon fontSize="small" />
          </span>
          {props.humidity} %
        </div>
        <div className="row">
          <span className="icon">
            <SpeedIcon fontSize="small" />
          </span>
          {Math.round(props.speed * 10) / 10} km/h
        </div>
        <div className="row">
          <span className="icon">
            <Battery4BarIcon fontSize="small" />
          </span>
          {props.voltage / 1000} V
        </div>
        <div className="row">
          <span className="icon">
            <SatelliteAltIcon fontSize="small" />
          </span>
          {props.satellites}
        </div>
      </div>
    </Popup>
  );
};
