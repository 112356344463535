import { divIcon } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import ReactLeafletDriftMarker from "react-leaflet-drift-marker";
import { DevicePopup } from "./DevicePopup";
import { Polyline, Popup } from "react-leaflet";
import { useDispatch } from "react-redux";
import types from "../../actions/actionTypes";
import { IPosition } from "../../reducers/positions";
import { useRef } from "react";

interface IProps {
  device: IPosition;
  devicePositions: IPosition[];
}

export const DeviceMarker: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const { device, devicePositions } = props;
  const popupref = useRef<any>(null);
  let markerPosition = device.latLng;
  const now = Date.now();
  const deviceDate = device.createdAt;
  let color = "#dedede";
  if (now - deviceDate < 600000) {
    color = device.color;
  }

  const hideOthers = () => {
    dispatch({ type: types.SHOW_ONLY, payload: device.dev_eui });
    // console.log("popupref", popupref.current);
    // console.log(popupref.current)
    // if (popupref && popupref.current) {
    // popupref?.current?.closePopup();
    // }
  };
  const iconMarkup = renderToStaticMarkup(
    <div>
      <div
        style={{
          backgroundColor: color,
        }}
        className="marker-pin"
      >
        <i className="marker-name">{device.deviceInitials}</i>
      </div>
      <div className="pulse"></div>
    </div>
  );
  const customMarkerIcon = divIcon({
    className: "custom-div-icon",
    html: iconMarkup,
  });

  return (
    <>
      <ReactLeafletDriftMarker
        position={markerPosition}
        duration={1000}
        icon={customMarkerIcon}
      >
        <DevicePopup
          deviceName={device.deviceName}
          temperature={device.temperature}
          speed={device.speed}
          humidity={device.humidity}
          voltage={device.voltage}
          satellites={device.satellites}
          hideOthers={hideOthers}
        />
      </ReactLeafletDriftMarker>
      <Polyline
        eventHandlers={{
          mouseover: (e: any) => {
            e.target.setStyle({ weight: 3 });
          },
          mouseout: (e: any) => {
            e.target.setStyle({ weight: 1 });
          },
        }}
        weight={1}
        positions={devicePositions.map((item) => item.latLng)}
        pathOptions={{
          color: devicePositions[devicePositions.length - 1].color,
        }}
      >
        <Popup ref={popupref}>
          <div style={{ cursor: "pointer" }} onClick={(e: any) => hideOthers()}>
            Skryt ostatne
          </div>
        </Popup>
      </Polyline>
    </>
  );
};
