import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MapIcon from "@mui/icons-material/Map";
import LogoutIcon from "@mui/icons-material/Logout";
import GroupIcon from "@mui/icons-material/Group";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { MenuItem } from "./MenuItem";

export const mainListItems = (
  <>
    <MenuItem
      // roles={["ADMIN", "SUPERADMIN", "USER"]}
      title="Trackery"
      link="/devices"
      icon={AutoAwesomeMotionIcon}
    />
    <MenuItem
      // roles={["ADMIN", "SUPERADMIN", "USER"]}
      title="Mapa"
      link="/map"
      icon={MapIcon}
    />
    <MenuItem
      // roles={["ADMIN", "SUPERADMIN", "USER"]}
      title="Logout"
      link="/logout"
      icon={LogoutIcon}
    />
    {/* <MenuItem
      roles={["ADMIN", "SUPERADMIN"]}
      title="Užívatelia"
      link="/users"
      icon={GroupIcon}
    /> */}
  </>
);

export const secondaryListItems = (
  <>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton>
  </>
);
