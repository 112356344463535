export class FetchError extends Error {
  message: string;
  data: string;
  code: string;
  constructor(message = "Something went wrong", data = "", code = "") {
    super();
    this.message = message;
    this.data = data;
    this.code = code;
  }
}
