import types from "../actions/actionTypes";

export interface IUser {
  accessToken: string;
  refreshToken: string;
  authenticated: boolean;
  isConnected: boolean;
  isOnline: boolean;
}

const initialState: IUser = {
  accessToken: "",
  refreshToken: "",
  authenticated: false,
  isConnected: false,
  isOnline: window.navigator.onLine,
};

export const user = (state: IUser = initialState, action: any): IUser => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      localStorage.setItem("user", JSON.stringify(action.value));
      return { ...action.value, authenticated: true };
    case types.LOGOUT:
      localStorage.removeItem("user");
      return initialState;
    case types.LOGIN_FAILURE:
      return initialState;
    case types.TOKEN_REFRESH_SUCCESS:
      localStorage.setItem(
        "user",
        JSON.stringify({ ...state, accessToken: action.value.accessToken })
      );
      return { ...state, accessToken: action.value.accessToken };
    case types.WS_CONNECTED:
      return { ...state, isConnected: action.payload };
    case types.ONLINE:
      return { ...state, isOnline: action.payload };
    default:
      return state;
  }
};
