import { LatLngTuple } from "leaflet";
import types from "../actions/actionTypes";

export interface IPosition {
  latLng: LatLngTuple;
  deviceName: string;
  deviceInitials: string;
  color: string;
  createdAt: number;
  temperature: number;
  humidity: number;
  speed: number;
  voltage: number;
  satellites: number;
  dev_eui: string;
}

export interface IPositions {
  [propName: string]: IPosition[];
}

const defaultState: IPositions = {};

export const positions = (
  state: IPositions = defaultState,
  action: any
): IPositions => {
  switch (action.type) {
    case types.SET_POSITIONS:
      return action.payload;
    case types.ADD_POSITION:
      console.log(action.payload);
      const newPositions: IPositions = { ...state };
      if (!newPositions[action.payload.dev_eui]) {
        newPositions[action.payload.dev_eui] = [];
      } else {
        if (newPositions[action.payload.dev_eui].length > 300) {
          newPositions[action.payload.dev_eui].slice(
            1,
            newPositions[action.payload.dev_eui].length
          );
        }
      }
      newPositions[action.payload.dev_eui].push({
        latLng: [action.payload.coords[0], action.payload.coords[1]],
        deviceName: action.payload.deviceName,
        deviceInitials: action.payload.initials
          ? action.payload.initials
          : "unknown",
        color: action.payload.color ? action.payload.color : "#ff0000",
        temperature: action.payload.temperature,
        humidity: action.payload.humidity,
        speed: action.payload.speed,
        satellites: action.payload.satellites,
        voltage: action.payload.voltage,
        createdAt: Date.parse(action.payload.publishedAt),
        dev_eui: action.payload.dev_eui,
      });
      return newPositions;

    default:
      return state;
  }
};
