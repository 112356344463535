import { combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import { loader } from "./loader";
import { IUser, user } from "./user";
import { IModal, modals } from "./modals";
import { ITrackers, trackers } from "./trackers";
import { IUserSettings, userSettings } from "./userSettings";
import { IUserDetails, userDetails } from "./userDetails";
import { IPositions, positions } from "./positions";
// import { showSuccessNotification } from "./showSuccessNotification";

export interface IState {
  router: RouterState;
  loader: boolean;
  user: IUser;
  userSettings: IUserSettings;
  userDetails: IUserDetails;
  modals: IModal;
  trackers: ITrackers;
  positions: IPositions;
}

export type IDispatch = (action: any) => void;

export const mainReducer = (history: History) =>
  combineReducers<IState>({
    loader,
    router: connectRouter(history),
    user,
    userSettings,
    userDetails,
    modals,
    trackers,
    positions,
  });
