import types from "../actions/actionTypes";

export const loader = (state: boolean = false, action: any): boolean => {
    switch (action.type) {
        case types.API_SUCCESS:
            return false;
        case types.API_START:
            return true
        default:return false
    }
};
