import React, { useEffect, useState } from "react";

import { sendRequest } from "../helpers/utils";
import { Auth } from "../components/Auth";
import { TrackerCard } from "../components/TrackerCard";
import {
  Alert,
  AppBar,
  Badge,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export function Trackers() {
  const [devices, setDevices] = useState<[]>([]);
  const [share, setShare] = useState<boolean>(false);
  const [shareEmail, setShareEmail] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const selectedCount = devices.filter((device: any) => device.selected).length;
  const openDialog = share && selectedCount > 0;
  const handleShare = async (e: React.MouseEvent) => {
    const body = {
      devId: devices
        .filter((device: any) => device.selected)
        .map((device: any) => device._id),
      userEmail: shareEmail,
    };
    await sendRequest(
      `//${process.env.REACT_APP_APIHOST}/device/share`,
      { method: "POST", useToken: true, body },
      (data) => {
        setSuccess(true);
        setShare(false);
        setDevices((prevState: any) => {
          return prevState.map((device: any) => ({
            ...device,
            selected: false,
          }));
        });
      },
      (e: any) => {
        setError(true);
        console.log(e);
      }
    );
  };

  const handleSelectClick = (e: React.MouseEvent, deviceId: string) => {
    setDevices((prevState: any) => {
      const newState = prevState.map((device: any) => {
        if (device._id === deviceId) {
          device.selected = !device.selected;
        }
        return device;
      });
      e.stopPropagation();
      return newState;
    });
  };

  useEffect(() => {
    const fetchDevices = async () => {
      await sendRequest(
        `//${process.env.REACT_APP_APIHOST}/device`,
        { method: "GET", useToken: true },
        (data) => {
          setDevices(
            data.result.map((d: any) => ({ ...d, id: d._id, selected: false }))
          );
          console.log(data);
        },
        (e: any) => {
          console.log(e);
        }
      );
    };

    fetchDevices();
  }, []); // Or [] if effect doesn't need props or state

  return (
    <Auth roles={["USER", "ADMIN", "SUPERADMIN"]}>
      <Dialog open={openDialog} onClose={() => setShare(false)}>
        <DialogTitle>Zdieľať</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Zadaj emailovú adresu užívateľa, komu chceš zdieľať
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => setShareEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShare(false)}>Zrušiť</Button>
          <Button onClick={(e) => handleShare(e)}>Zdieľať</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Zdieľanie úspešné
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert
          onClose={() => setError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          Skontrolujte emailovu adresu.
        </Alert>
      </Snackbar>
      <div style={{ margin: "10px" }}>
        <AppBar position="static" color="default" elevation={0}>
          <Toolbar variant="dense">
            <Typography
              variant="h6"
              color="inherit"
              component="div"
              style={{ marginRight: "10px" }}
            >
              <Badge badgeContent={selectedCount} color="primary">
                {selectedCount > 0 ? "Označené" : "Nie je nič označené"}
              </Badge>
            </Typography>
            <ButtonGroup
              size="small"
              variant="outlined"
              aria-label="outlined button group"
            >
              <IconButton
                size="small"
                onClick={(e: React.MouseEvent) =>
                  setShare(selectedCount > 0 ? !share : false)
                }
              >
                <ShareIcon fontSize="small" />
              </IconButton>
              <IconButton size="small" color="error">
                <DeleteForeverIcon fontSize="small" />
              </IconButton>
            </ButtonGroup>
          </Toolbar>
        </AppBar>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {devices.map((device: any) => (
            <TrackerCard
              key={device._id}
              handleSelectClick={handleSelectClick}
              deviceId={device._id}
              title={device.deviceName}
              initials={device.initials}
              color={device.color}
              eui={device.eui}
              selected={device.selected}
            />
          ))}
        </div>
      </div>
    </Auth>
  );
}
