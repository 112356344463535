import { IPosition, IPositions } from "../reducers/positions";
import { ITrackers } from "../reducers/trackers";

export const parsePositions = (data: any) => {
  if (data && Array.isArray(data)) {
    const historyPositions: IPositions = {};
    for (const m of data) {
      if (!historyPositions[m.dev_eui]) {
        historyPositions[m.dev_eui] = [];
      }
      historyPositions[m.dev_eui].push({
        latLng: [m.coords[0], m.coords[1]],
        deviceName: m.deviceName,
        deviceInitials: m.initials ? m.initials : "unknown",
        color: m.color ? m.color : "#ff0000",
        createdAt: Date.parse(m.publishedAt),
        temperature: m.temperature,
        humidity: m.humidity,
        speed: m.speed,
        voltage: m.voltage,
        satellites: m.satellites,
        dev_eui: m.dev_eui,
      });
    }
    return historyPositions;
  } else {
    const position: IPosition = {
      latLng: [data.coords[0], data.coords[1]],
      deviceName: data.deviceName,
      deviceInitials: data.initials ? data.initials : "unknown",
      color: data.color ? data.color : "#ff0000",
      temperature: data.temperature,
      humidity: data.humidity,
      speed: data.speed,
      satellites: data.satellites,
      voltage: data.voltage,
      createdAt: Date.parse(data.publishedAt),
      dev_eui: data.dev_eui,
    };
    return position;
  }
};

export const parseTrackerSettings = (data: any) => {
  const trackerSettings: ITrackers = {};
  if (data && Array.isArray(data)) {
    data.forEach((m) => {
      if (!Object.keys(trackerSettings).includes(m.dev_eui)) {
        trackerSettings[m.dev_eui] = {
          show: true,
          deviceName: m.deviceName,
          deviceInitials: m.initials ? m.initials : "unknown",
          color: m.color ? m.color : "#ff0000",
          temperature: m.temperature,
          humidity: m.humidity,
          speed: m.speed,
          satellites: m.satellites,
          voltage: m.voltage,
          dev_eui: m.dev_eui,
        };
      }
    });
    return trackerSettings;
  }
  return {};
};
