import classNames from "classnames";
import "./Bubble.css";

interface IProps {
  isOnline: boolean;
}
export const Bubble: React.FC<IProps> = (props) => (
  <div id="center-div">
    <div className={classNames("bubble", { online: props.isOnline })}>
      <span
        className={classNames("bubble-outer-dot", { online: props.isOnline })}
      >
        <span
          className={classNames("bubble-inner-dot", {
            online: props.isOnline,
          })}
        ></span>
      </span>
    </div>
  </div>
);
